/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as _ from "lodash-es";
import {
    Fragment,
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from "react";

import { StrapiProductFeature } from "@/types/strapi";

import {
    HalfColumnGaps,
    NavigationSpacerHeight,
    Spacing,
} from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { ProductFeatureMediaCard } from "@/ui/atoms/product_feature_media_card";
import { TextHeader } from "@/ui/molecules/text_header";

import { useProductFeaturePanelExpansionAnimation } from "@/util/animation_hooks/product_feature_animations";
import { useGlobalsContext } from "@/util/context/globals_context";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

import {
    ProductFeaturePanel,
    ProductFeaturePanelRef,
} from "./product_feature_panel";

interface ProductFeatureProps extends StrapiProductFeature {}

export const ProductFeature: FunctionComponent<ProductFeatureProps> = (
    props,
) => {
    /**
     * Globals
     */
    const featureCount = props.Features.length;

    /**
     * Refs
     */
    const [panelsRef, setPanelsRef] = useArrayRef<ProductFeaturePanelRef>();

    /**
     * Context
     */
    const { browser } = useGlobalsContext();

    /**
     * States
     */
    const [activeFeatureIndex, setActiveFeatureIndex] = useState(0);
    const [isTimerEnabled, setIsTimerEnabled] = useState(true);

    const nextFeature = useCallback(() => {
        setActiveFeatureIndex((current) => (current + 1) % featureCount);
    }, [featureCount]);

    /**
     * Animations
     */
    useProductFeaturePanelExpansionAnimation(panelsRef, activeFeatureIndex);

    /**
     * Styles
     */
    const gridContainerStyles = css(
        /**
         * On M, L, XL breakpoints, the height of the section should be full
         * height minus 3 half column gaps and the height of the navigation bar
         */
        buildStylesByBreakpoint(
            "height",
            _.mapValues(
                _.pick(HalfColumnGaps, ["medium", "large", "extraLarge"]),
                (halfColumnGap, key) => {
                    const spacerHeight =
                        NavigationSpacerHeight[
                            key as keyof typeof NavigationSpacerHeight
                        ];
                    if (spacerHeight) {
                        return `calc(100svh - ${spacerHeight} - (${halfColumnGap} * 3))`;
                    }
                    return undefined;
                },
            ),
        ),
        // Max height of the panel
        buildStylesByBreakpoint("maxHeight", {
            medium: convertToRem(880),
            large: convertToRem(1160),
        }),
    );

    const textColumnStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("justifyContent", {
            medium: "space-between",
        }),
        buildStylesByBreakpoint("rowGap", {
            extraSmall: Spacing["spacing-8"],
            small: Spacing["spacing-10"],
        }),
    );

    const mediaColumnStyles = useMemo(
        () =>
            css(
                {
                    gridColumnEnd: -1,
                    gridColumnStart: 7,
                    position: "relative",
                },
                buildStylesByBreakpoint("display", {
                    extraSmall: "none",
                    medium: "grid",
                }),
                buildStylesByBreakpoint(
                    "paddingTop",
                    _.mapValues(
                        HalfColumnGaps,
                        (value) => `calc(${featureCount - 1} * ${value})`,
                    ),
                ),
            ),
        [featureCount],
    );

    /**
     * Rendering
     */
    const renderProductFeatures = () => {
        function handlePanelClick(panelIndex: number): void {
            setActiveFeatureIndex(panelIndex);
            setIsTimerEnabled(false);
        }

        return (
            <ul>
                {props.Features.map((feature, index) => {
                    /**
                     * Show divider if the panel is not the last one
                     * or is not adjacent to the active one.
                     */
                    const showDivider =
                        index !== featureCount - 1 &&
                        index !== activeFeatureIndex &&
                        index + 1 !== activeFeatureIndex;

                    const dividerStyles = css({
                        visibility: showDivider ? "visible" : "hidden",
                    });

                    return (
                        <Fragment key={`product-feature::panel::${feature.id}`}>
                            <ProductFeaturePanel
                                {...feature}
                                index={index}
                                isActive={index === activeFeatureIndex}
                                isTimerEnabled={isTimerEnabled}
                                ref={setPanelsRef}
                                onClick={() => handlePanelClick(index)}
                                onTimerComplete={nextFeature}
                            />

                            <Divider className={dividerStyles} />
                        </Fragment>
                    );
                })}
            </ul>
        );
    };

    return (
        <GridContainer className={gridContainerStyles} legacyGrid={false}>
            <GridColumn
                className={textColumnStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                    large: 5,
                }}
            >
                <TextHeader {...props.Header} renderAsGrid={false} />

                {renderProductFeatures()}
            </GridColumn>

            <GridColumn className={mediaColumnStyles}>
                {props.Features.map((feature, index) => (
                    <ProductFeatureMediaCard
                        feature={feature}
                        key={feature.id}
                        offset={
                            (featureCount - activeFeatureIndex + index) %
                            featureCount
                        }
                        total={featureCount}
                    />
                ))}
            </GridColumn>
        </GridContainer>
    );
};
