import * as _ from "lodash-es";

import { convertToRem } from "./ui_util";

export function getCardTransform(
    cardHeight: number,
    offset: number,
    space: number,
): string {
    if (offset === 0) {
        return "scale(1) translateY(0)";
    }
    const scale = _.round(1 - (offset * space * 2) / cardHeight, 4);
    const translateY = convertToRem(-offset * space);
    return `scale(${scale}) translateY(${translateY})`;
}
