import gsap, { Expo } from "gsap";
import * as _ from "lodash-es";
import { useEffect, useState } from "react";

import { TypedArrayRefObject, TypedRefObject } from "@/types/interactivity";

import { Spacing } from "@/tokens/spacing";

import { ProductFeaturePanelRef } from "@/ui/organisms/product_feature_panel";

import { useIsomorphicLayoutEffect } from "@/util/hooks/effect_hooks";
import { getCardTransform } from "@/util/product_feature_media_card_util";

/**
 * Animates the different parts of the feature panel
 * depending on which index is active
 */
export function useProductFeaturePanelExpansionAnimation(
    panelsRef: TypedArrayRefObject<ProductFeaturePanelRef>,
    activeIndex: number,
) {
    /**
     * Variables
     */
    const marginBottom = Spacing["spacing-4"];
    const ease = Expo.easeInOut;

    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useIsomorphicLayoutEffect(() => {
        if (panelsRef.current) {
            const firstPanel = panelsRef.current[0];

            const remainingPanels = panelsRef.current.slice(1);

            if (firstPanel) {
                gsap.set(firstPanel.backgroundRef.current, {
                    autoAlpha: 1,
                    scale: 1,
                });

                gsap.set(firstPanel.progressBarRef.current, {
                    autoAlpha: 1,
                    height: "auto",
                    marginBottom,
                });

                gsap.set(firstPanel.detailsRef.current, {
                    autoAlpha: 1,
                    height: "auto",
                    onComplete: () => {
                        setDidInit(true);
                    },
                });
            }

            if (remainingPanels) {
                remainingPanels.forEach((remainingPanel) => {
                    if (remainingPanel) {
                        gsap.set(remainingPanel.backgroundRef.current, {
                            autoAlpha: 0,
                            scale: 0.9,
                        });
                        gsap.set(remainingPanel.progressBarRef.current, {
                            autoAlpha: 0,
                            height: 0,
                            marginBottom: 0,
                        });
                        gsap.set(remainingPanel.detailsRef.current, {
                            autoAlpha: 0,
                            height: 0,
                        });
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (didInit && panelsRef.current) {
            const duration = 0.75;
            const activePanel = panelsRef.current[activeIndex];

            const inactivePanels = _.filter(
                panelsRef.current,
                (_, i) => i !== activeIndex,
            );

            if (activePanel) {
                gsap.to(activePanel.backgroundRef.current, {
                    autoAlpha: 1,
                    duration,
                    ease,
                    scale: 1,
                });
                gsap.to(activePanel.detailsRef.current, {
                    autoAlpha: 1,
                    duration,
                    ease,
                    height: "auto",
                });
                gsap.to(activePanel.progressBarRef.current, {
                    autoAlpha: 1,
                    duration,
                    ease,
                    height: "auto",
                    marginBottom,
                });
            }

            if (inactivePanels) {
                inactivePanels.forEach((inactivePanel) => {
                    if (inactivePanel) {
                        gsap.to(inactivePanel.backgroundRef.current, {
                            autoAlpha: 0,
                            duration,
                            ease,
                            scale: 0.9,
                        });
                        gsap.to(inactivePanel.progressBarRef.current, {
                            autoAlpha: 0,
                            duration,
                            ease,
                            height: 0,
                            marginBottom: 0,
                        });
                        gsap.to(inactivePanel.detailsRef.current, {
                            autoAlpha: 0,
                            duration,
                            ease,
                            height: 0,
                        });
                    }
                });
            }
        }
    }, [didInit, activeIndex]);
}

/**
 * Collapses, expands, and swaps the order of
 * the media card stack depending on offset change
 */
export function useDesktopMediaCardExpansionAnimation(
    cardRef: TypedRefObject,
    cardHeight: number,
    offset: number,
    total: number,
) {
    useEffect(() => {
        const ease = Expo.easeOut;

        const duration = 0.75;
        const opacityAnimation = 0.15;

        const timeline = gsap.timeline();

        timeline.to(cardRef.current, {
            duration: (duration - opacityAnimation) / 2,
            ease,
            transform: getCardTransform(cardHeight, 0, 14),
        });
        timeline.to(cardRef.current, {
            autoAlpha: offset !== 0 ? 0 : undefined,
            duration: opacityAnimation,
        });
        timeline.to(cardRef.current, {
            duration: 0,
            zIndex: total - offset,
        });
        timeline.to(
            cardRef.current,
            {
                autoAlpha: 1,
                duration: (duration - opacityAnimation) / 2,
                ease,
                transform: getCardTransform(cardHeight, offset, 14),
            },
            "<",
        );
    }, [cardHeight, cardRef, offset, total]);
}
